
export default {
    methods:{
        async getOptions(){
            try {
                const {data} = await this.$axios.get(`/storyblok/help-options`)

                // setTimeout(()=> {
                //     console.log('options setting now')
                //     this.options = data.options
                // }, 15000)
                
                this.options = data.options
                
            }catch (e){
                console.error(e);
            }
        },
        parseNumber(phoneNumber){
            if(!phoneNumber) return ''
            return  phoneNumber.replace(/[^a-zA-Z0-9]/g, '');
        }
    },
    
    data(){
        return {
            open : false,
            options: []
        }
    },
    created(){
        this.getOptions();
    },
    watch : {
        $route : {
            deep :true,
            handler(){
                this.open = false;
            }
        }
    },
    

}
